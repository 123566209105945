import httpService from "./http.service";

const taskEndpoint = "tasks";
const checkTaskEndpoint = "tasks/check";
const checkDailyTransactionEndpoint = "daily-transaction";
const dailyTransactionPrecheckoutEndpoint = "daily-transaction/precheckout";
const dailyTransactionProcessEndpoint = "daily-transaction/process";

const taskService = {
  fetchAll: async () => {
    const { data } = await httpService.get(taskEndpoint);
    return data;
  },
  check: async (payload: string) => {
    const { data } = await httpService.get(checkTaskEndpoint, {
      params: {
        task_id: payload
      }
    });
    return data;
  },
  checkDailyTransaction: async () => {
    const { data } = await httpService.get(checkDailyTransactionEndpoint);
    return data;
  },
  dailyTransactionPrecheckout: async (sourceAddressUserFriendly: string) => {
    const { data } = await httpService.post(dailyTransactionPrecheckoutEndpoint, {
      sourceAddressUserFriendly,
    });
    return data;
  },
  dailyTransactionProcess: async (hash: string, boc: string) => {
    const { data } = await httpService.post(dailyTransactionProcessEndpoint, {
      hash, boc,
    })
    return data;
  }
};

export default taskService;
