import {CSSProperties, FC, useState} from 'react';
import { createPortal } from 'react-dom';
import styles from './TonTransactionDailyModal.module.css';
import icons from '../../../utils/icons';
import MainButton from '../MainButton';
import { useTranslation } from 'react-i18next';
import convertNumber from "../../../utils/numbers.ts";
import {SendTransactionRequest, useTonAddress, useTonConnectUI, useTonWallet} from "@tonconnect/ui-react";
import {beginCell, Cell} from "@ton/ton";
import taskService from "../../../services/task.service.ts";

type TonTransactionDailyModalProps = {
    text: string,
    label: string,
    open: boolean,
    silver: number,
    gold: number,
    onClose: (active: boolean) => void,
    style?: CSSProperties
};

export const TonTransactionDailyModal: FC<TonTransactionDailyModalProps> = ({ onClose, open, label, text, silver, gold }) => {
    const {t} = useTranslation('ns1');
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const sourceAddressUserFriendly = useTonAddress();
    const wallet = useTonWallet();

    const [tonConnectUI] = useTonConnectUI();

    const onSendTransaction = async () => {
        if (!wallet || !sourceAddressUserFriendly) {
            return;
        }

        try {
            setIsLoading(true);
            const { content: {invoice_id, destination_wallet, grams} } = await taskService.dailyTransactionPrecheckout(sourceAddressUserFriendly);

            const body = beginCell()
                .storeUint(0, 32) // write 32 zero bits to indicate that a text comment will follow
                .storeStringTail(invoice_id) // write our text comment
                .endCell();

            const transaction: SendTransactionRequest = {
                validUntil: Date.now() + 5 * 60 * 1000, // 5 minutes
                messages: [
                    {
                        address: destination_wallet, // message destination in user-friendly format
                        amount: grams, // Toncoin in nanotons
                        payload: body.toBoc().toString("base64"),
                    },
                ],
            };

            const resultTransaction = await tonConnectUI.sendTransaction(transaction);
            const extHash = Cell.fromBase64(resultTransaction.boc).hash().toString('hex')

            await taskService.dailyTransactionProcess(extHash, resultTransaction.boc)

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false)
            console.log({error})
        }
    }

    return createPortal(
        <>
            <div className={`${styles.TonTransactionDailyModal} ${open ? styles.show : ""}`} >
                <div
                    className={styles.Overlay}
                    onClick={(e) => {
                        e.stopPropagation();
                        onClose(false);
                    }}
                />
                <div className={styles.Body}>
                    <div className={styles.Header}>
                        <button
                            className={styles.CloseBtn}
                            onClick={(e) => {
                                e.stopPropagation();
                                onClose(false);
                            }}
                        >
                            { icons.timesIcon }
                        </button>
                    </div>
                    <div className={styles.Wrapper}>
                        <div className={styles.Picture}>
                            <div className={styles.Light}></div>
                            <icons.toncoinIcon className={styles.Image} width={100} height={100} />
                        </div>
                        {label && <h1 className={styles.Title}>{t(`${label}`)}</h1>}
                        {text && <div className={styles.Text}>{t(`${text}`)}</div>}
                        <div className={styles.RewardWrapper}>
                            <span className={styles.RewardTitle}>Reward</span>
                            <div className={styles.RewardList}>
                                <div className={styles.RewardItem}>
                                    <div className={`${styles.RewardIcon}`}>
                                        {icons.silverIcon}
                                    </div>
                                    <div className={styles.Reward}>
                                        +{convertNumber(silver)}
                                    </div>
                                </div>
                                <div className={styles.RewardItem}>
                                    <div className={`${styles.RewardIcon}`}>
                                        {icons.goldIcon}
                                    </div>
                                    <div className={styles.Reward}>
                                        +{convertNumber(gold)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.WrapperSecond}>
                        <MainButton
                            heading='Complete the task'
                            onTouch={onSendTransaction}
                            isLoading={isLoading}
                        />
                    </div>
                </div>
            </div>
        </>,
        document.getElementById('daily') as HTMLElement);
}
