import React, { CSSProperties, useState } from 'react';
import styles from './TonConnectDailyCard.module.css';
import icons from '../../../utils/icons';
import { useTranslation } from 'react-i18next';
import {TonConnectDailyModal} from "../TonConnectDailyModal";

type TonConnectDailyCardProps = {
    title: string,
    description: string,
    modalText: string,
    style?: CSSProperties,
    disabled?: boolean,
    props?: React.HTMLAttributes<HTMLButtonElement>
};

export const TonConnectDailyCard: React.FC<TonConnectDailyCardProps> = ({ title, description, modalText, style, disabled, ...props }) => {
    const [show, setShow] = useState(false);
    const {t} = useTranslation('ns1');

    const handleOpenModal = () => {
        setShow(true);
    };

    return (
        <>
            <button
                onClick={handleOpenModal}
                className={styles.TonConnectDailyCard}
                disabled={disabled}
                {...props}
            >
                <div className={styles.Wrap}>
                    <div className={styles.Title}>
                        {t(`${title}`)}
                    </div>
                    <div className={styles.Info}>
                        <div className={styles.Description}>
                            {t(`${description}`)}
                        </div>
                    </div>
                </div>
                <div className={styles.Icon}>
                    <icons.toncoinIcon/>
                </div>
            </button>
            <TonConnectDailyModal
                text="Example text"
                label="Connect TON wallet"
                open={show}
                onClose={() => setShow(false)}
            />
        </>
    )
}
