import React, { CSSProperties, useState } from 'react';
import styles from './TonTransactionDailyCard.module.css';
import icons from '../../../utils/icons';
import { useTranslation } from 'react-i18next';
import convertNumber from "../../../utils/numbers.ts";
import {TonTransactionDailyModal} from "../TonTransactionDailyModal";
import {useAppSelector} from "../../../hooks/hooks.ts";
import {getTransactionDaily} from "../../../store/tasks.ts";

type TonTransactionDailyCardProps = {
    title: string,
    silver: number,
    gold: number,
    modalText: string,
    style?: CSSProperties,
    disabled?: boolean,
    props?: React.HTMLAttributes<HTMLButtonElement>
};

export const TonTransactionDailyCard: React.FC<TonTransactionDailyCardProps> = ({ title, silver, gold, modalText, style, disabled, ...props }) => {
    const [show, setShow] = useState(false);
    const dailyTransaction = useAppSelector(getTransactionDaily());
    const {t} = useTranslation('ns1');

    const isCompleted = dailyTransaction.data.state === 'completed';

    const handleOpenModal = () => {
        if (!isCompleted) {
            setShow(true);
        }
    };


    return (
        <>
            <button
                onClick={handleOpenModal}
                className={`${styles.TonTransactionDailyCard} ${isCompleted ? styles.TonTransactionDailyCardCompleted : ''}`}
                disabled={disabled}
                {...props}
            >
                <div className={styles.Wrap}>
                    <div className={styles.Title}>
                        {t(`${title}`)}
                    </div>
                    <div className={styles.Info}>
                        <div className={styles.RewardContainer}>
                            <div className={styles.RewardItem}>
                                <div className={`${styles.RewardIcon}`}>
                                    {icons.silverIcon}
                                </div>
                                <div className={styles.Reward}>
                                    +{convertNumber(silver)}
                                </div>
                            </div>
                            <div className={styles.RewardItem}>
                                <div className={`${styles.RewardIcon}`}>
                                    {icons.goldIcon}
                                </div>
                                <div className={styles.Reward}>
                                    +{convertNumber(gold)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.Icon}>
                    {
                        isCompleted ? icons.doneIcon : <icons.toncoinIcon/>
                    }
                </div>
            </button>
            <TonTransactionDailyModal
                text="Make a transaction in the TON network and receive a reward"
                label="Daily TON transaction"
                silver={silver}
                gold={gold}
                open={show}
                onClose={() => setShow(false)}
            />
        </>
    )
}
