import styles from './EarnPage.module.css';
import Navbar from '../../ui/Navbar';
import ExpLogoSrc from "../../../assets/images/XP_x3_logo.webp";
import TaskCard from '../../ui/TaskCard';
import { Task } from '../../../interfaces/task.type';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import {getTasksData, getTransactionDaily, loadTasks, loadTransactionDaily} from '../../../store/tasks';
import { useEffect, useState } from 'react';
import { getGameDailyRewardsData, getGameDailyRewardsStatus, loadDailyRewards } from '../../../store/game';
import { Reward } from '../../../interfaces/reward.type';
import DailyCard from '../../ui/DailyCard';
import { useTranslation } from 'react-i18next';
import {useTonWallet} from "@tonconnect/ui-react";
import {TonConnectDailyCard} from "../../ui/TonConnectDailyCard";
import {TonTransactionDailyCard} from "../../ui/TonTransactionDailyCard";

export default function EarnPage() {
  const dispatch = useAppDispatch();
  const {t} = useTranslation('ns1');
  const dailyDataStatus = useAppSelector(getGameDailyRewardsStatus());
  const dailyTransaction = useAppSelector(getTransactionDaily());
  const tasks = useAppSelector(getTasksData());
  const [ isTook, setIsTook ] = useState(false);
  const rewardList = useAppSelector(getGameDailyRewardsData());
  const wallet = useTonWallet();
  let lastRewarded: Reward | undefined;
  const availibleReward = rewardList?.filter((reward: Reward) => reward.status === "available")[0];
  if (!availibleReward) {
    lastRewarded = rewardList?.filter((reward: Reward) => reward.status === "rewarded")?.pop();
  }

  const validate = (items: Reward[] | null) => {
    let isValid = false;
    if (!items) return;
    for (const item of items) {
      if (item.status === "available") {
        isValid = true;
      }
    }
    return isValid;
  };

  useEffect(() => {
    dispatch(loadTasks())
    dispatch(loadTransactionDaily())
  }, [dispatch]);

  useEffect(() => {
    if (!dailyDataStatus) dispatch(loadDailyRewards())
  }, [dailyDataStatus, dispatch]);

  useEffect(() => {
    if (validate(rewardList)) {
      setIsTook(false)
    } else {
      setIsTook(true)
    }
  }, [rewardList]);

  return (
    <div className={styles.EarnPage}>
      <div className={styles.Header}>
        <div className={styles.Heading}>{t('Earn more')}</div>
        <div className={styles.ExpLogo}>
          <div className={styles.Light}></div>
          <img className={styles.ExpLogoImg} src={ExpLogoSrc} alt="Xp logo" />
        </div>
      </div>
      {
        (!tasks || !dailyDataStatus || !dailyTransaction.data) ? <h1>Loading...</h1> : <div className={styles.Wrapper}>
          <h4>{t('Daily task')}</h4>
          <ul className={styles.List}>
            <DailyCard
              image='calendar'
              reward_currency='exp'
              title='Get your daily reward'
              description={isTook ? 'You took your reward' : 'Pick up your reward'}
              modalText="daily text modal"
              reward_amount={availibleReward?.reward || lastRewarded?.reward || 0}
              status={isTook}
            />
            {!wallet && <TonConnectDailyCard
                title="TON Wallet"
                description="Connect wallet"
                modalText="Modal text"
            />}
            {wallet && (
                <TonTransactionDailyCard
                  title="Daily TON transaction"
                  silver={975}
                  gold={150}
                  modalText="Make a transaction in the TON network and receive a reward"
                />
            )}
          </ul>
          <h4>{t('List of tasks')}</h4>
          <ul className={styles.List}>
            {tasks ? tasks.map((item: Task) => (
              <TaskCard
                key={item.id}
                id={item.id}
                title={item.title}
                description={item.description}
                reward_currency={item.reward_currency}
                reward_amount={item.reward_amount}
                type={item.type}
                link={item.link}
                start_ts={item?.start_ts}
                end_ts={item?.end_ts}
                user_task_status={item.user_task_status}
                disabled={item.user_task_status === "rewarded"}
              />
            )) : ""}
          </ul>
        </div>
      }
      <Navbar />
    </div>
  )
}
