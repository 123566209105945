import { CSSProperties, FC } from 'react';
import { createPortal } from 'react-dom';
import styles from './TonConnectDailyModal.module.css';
import icons from '../../../utils/icons';
import MainButton from '../MainButton';
import { useTranslation } from 'react-i18next';
import {useTonConnectModal} from "@tonconnect/ui-react";

type TonConnectDailyModalProps = {
    text: string,
    label: string,
    open: boolean,
    onClose: (active: boolean) => void,
    style?: CSSProperties
};

export const TonConnectDailyModal: FC<TonConnectDailyModalProps> = ({ onClose, open, label, text }) => {
    const {t} = useTranslation('ns1');

    const { open: openTonConnectModal } = useTonConnectModal();

    return createPortal(
        <>
            <div className={`${styles.TonConnectDailyModal} ${open ? styles.show : ""}`} >
                <div
                    className={styles.Overlay}
                    onClick={(e) => {
                        e.stopPropagation();
                        onClose(false);
                    }}
                />
                <div className={styles.Body}>
                    <div className={styles.Header}>
                        <button
                            className={styles.CloseBtn}
                            onClick={(e) => {
                                e.stopPropagation();
                                onClose(false);
                            }}
                        >
                            { icons.timesIcon }
                        </button>
                    </div>
                    <div className={styles.Wrapper}>
                        <div className={styles.Picture}>
                            <div className={styles.Light}></div>
                            <icons.toncoinIcon className={styles.Image} width={100} height={100} />
                        </div>
                        {label && <h1 className={styles.Title}>{t(`${label}`)}</h1>}
                        {text && <div className={styles.Text}>{t(`${text}`)}</div>}
                    </div>
                    <div className={styles.WrapperSecond}>
                        <MainButton
                            heading='Connect'
                            onTouch={openTonConnectModal}
                        />
                    </div>
                </div>
            </div>
        </>,
        document.getElementById('daily') as HTMLElement);
}
